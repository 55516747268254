// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CircularProgress from "@mui/material/CircularProgress";
import "./table.css";

// Data
import { useEffect, useState } from "react";
import axios from "axios";

function LiveUsers() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const result = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/v1/dashboard/sockets`,
        {
          withCredentials: true,
        }
      );

      setIsLoading(false);
      setData(result.data);
    };
    load();
    const timer = setInterval(() => load(), 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <CircularProgress color="success" />}
      {data && (
        <table className="userstable">
          <thead>
            <tr>
              <th>User</th>
              <th>Nickname</th>
              <th>Sockets/Tabs</th>
            </tr>
          </thead>
          <tbody>
            {data.map((u) => (
              <tr key={u}>
                <td>{u.address}</td>
                <td>{u.nickname}</td>
                <td>
                  <ul>{u.sockets.length}</ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </DashboardLayout>
  );
}

export default LiveUsers;
