// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DatePicker from "react-datepicker";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import BetsTable from "layouts/bets/BetsTable";
import JournalTable from "layouts/journal/JournalTable";

import UserCard from "layouts/user/components/UserCard";
import Deposits from "layouts/user/components/Deposits";
import Withdraws from "layouts/user/components/Withdraws";
import NetworkInformation from "layouts/user/components/NetworkInformation";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import axios from "axios";
import moment from "moment";

function User() {
  const { address } = useParams();
  const [user, setUser] = useState();
  const [enteredAddress, setEnteredAddress] = useState("");
  const [postStats, setPostStats] = useState();
  const [journalData, setJournalData] = useState();
  const [dateTo, setDateTo] = useState(new Date(moment.utc()));
  const [dateFrom, setDateFrom] = useState(new Date(moment.utc()));

  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      const addressCheckSum = address; //ethers.utils.getAddress(address);
      const [result, journal] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_API_URI}/api/v1/dashboard/users/${addressCheckSum}`,
          {
            withCredentials: true,
          }
        ),
        axios.get(
          `${process.env.REACT_APP_API_URI}/api/v1/dashboard/journals/${addressCheckSum}`,
          {
            withCredentials: true,
          }
        ),
      ]);

      console.log("user data", result.data);

      setUser(result.data);
      setJournalData(journal.data);
    };
    if (address) load();
    else {
      setUser(null);
    }
  }, [address]);

  useEffect(() => {
    const load = async () => {
      const addressCheckSum = address; //ethers.utils.getAddress(address);
      const postStatistics = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/v1/dashboard/users/${addressCheckSum}`,
        {
          withCredentials: true,
          dateFrom,
          dateTo,
        }
      );
      console.log(postStatistics.data);

      let statsResult = {
        numBets: 0,
        numWon: 0,
        numLost: 0,
        totalBet: 0,
        houseProfit: 0,
        totalDeposit: postStatistics.data.deposit,
        totalWithdraw: postStatistics.data.withdraw,
      };
      for (let i = 0; i < postStatistics.data.bets.length; i++) {
        const s = postStatistics.data.bets[i];
        if (s._id) statsResult.numWon += s.numBets;
        else statsResult.numLost += s.numBets;
        statsResult.numBets += s.numBets;
        statsResult.houseProfit =
          statsResult.houseProfit + s.amountUSD - s.amountWonUSD;
        statsResult.totalBet += s.amountUSD;
      }
      console.log(statsResult);

      setPostStats(statsResult);
    };
    if (address) load();
  }, [address, dateFrom, dateTo]);

  const loadAddress = () => {
    navigate("/user/" + enteredAddress);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!address && (
        <MDBox mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Enter user address"
                value={enteredAddress}
                variant="standard"
                onChange={(e) => setEnteredAddress(e.target.value)}
              />
              <MDButton
                fullWidth
                variant="gradient"
                color="info"
                size="small"
                onClick={loadAddress}
              >
                Load
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {user && (
        <MDBox mt={8}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <UserCard user={user} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {user.balances
                    .filter((b) => b.amount > 0.0000001)
                    .map((b) => (
                      <Grid
                        key={b.network + b.token}
                        item
                        xs={12}
                        md={6}
                        xl={2}
                      >
                        <DefaultInfoCard
                          icon="account_balance"
                          title={b.network + " " + b.token}
                          description="balance"
                          value={b.amount > 0 ? b.amount.toFixed(2) : "0,00"}
                        />
                      </Grid>
                    ))}
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="warning"
                      title="Total Bonus"
                      description="USD"
                      value={user.totalBonus.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="success"
                      title={"Won:" + user.numWon}
                      description={"Num Bets:" + user.numBets}
                      value={
                        parseInt(
                          (user.numWon / (user.numWon + user.numLost)) * 100
                        ) + "%"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="Total Bet"
                      description="USD"
                      value={user.totalBet.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="House Profit"
                      description="USD"
                      value={(user.houseProfit - user.totalBonus).toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="Total Deposit"
                      description="USD"
                      value={user.totalDeposit?.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="Total Withdraw"
                      description="USD"
                      value={user.totalWithdraw?.toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mb={3}>
            <Grid container spacing={3}>
              UserStats <br />
              From the beginning of:{" "}
              <DatePicker
                selected={dateFrom}
                onChange={(d) => setDateFrom(d)}
              />
              To the end of:{" "}
              <DatePicker selected={dateTo} onChange={(d) => setDateTo(d)} />
              <br />
              {postStats && (
                <>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="info"
                      title={"POST Won:" + postStats.numWon}
                      description={"POST Num Bets:" + postStats.numBets}
                      value={
                        parseInt(
                          (postStats.numWon /
                            (postStats.numWon + postStats.numLost)) *
                            100
                        ) + "%"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="info"
                      title={"POST TotalBet USD"}
                      value={postStats.totalBet.toFixed(3)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="info"
                      title={"POST HouseProfit"}
                      value={postStats.houseProfit.toFixed(3)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="info"
                      title={"POST Deposit USD"}
                      value={postStats.totalDeposit.toFixed(3)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="info"
                      title={"POST Withdraw USD"}
                      value={postStats.totalWithdraw.toFixed(3)}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </MDBox>

          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <NetworkInformation balances={user.balances} />
              </Grid>

              <Grid item xs={12} md={10}>
                <BetsTable data={user.bets} columnsIgnored={{ user: true }} />
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Deposits data={user.deposits} />
              </Grid>

              <Grid item xs={6}>
                <Withdraws data={user.withdraws} />
              </Grid>
            </Grid>
          </MDBox>

          {journalData && (
            <MDBox mb={3}>
              <JournalTable
                data={journalData}
                columnsIgnored={{ user: true }}
              />
            </MDBox>
          )}
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default User;
