import { useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const NewBet = () => {
  const [target, setTarget] = useState("usd/jpy");
  const [amount, setAmount] = useState(1);
  const [position, setPosition] = useState(0);
  const [interval, setInterval] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleBet = async () => {
    setIsLoading(true);
    setMessage("");
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/v1/prediction/bets`,
        {
          network: "polygon",
          token: "matic",
          target,
          interval,
          position,
          amount,
        },
        {
          headers: { cookie: global.authTokens },
        }
      );
      setMessage(result.data.message);
      console.log(result.data.message);
    } catch (err) {
      setMessage("bet failed");
      console.log(err.response.status, err.response.data);
    }
    setIsLoading(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {" "}
            <MDInput
              fullWidth
              label="Target"
              value={target}
              variant="standard"
              onChange={(e) => setTarget(e.target.value)}
            />
            <MDInput
              fullWidth
              label="Amount Matic"
              value={amount}
              variant="standard"
              onChange={(e) => setAmount(e.target.value)}
            />
            <MDInput
              fullWidth
              label="Position 0:up, 1:down"
              value={position}
              variant="standard"
              onChange={(e) => setPosition(e.target.value)}
            />
            <MDInput
              fullWidth
              label="Interval Seconds"
              value={interval}
              variant="standard"
              onChange={(e) => setInterval(e.target.value)}
            />
            {!isLoading && (
              <MDButton
                fullWidth
                variant="gradient"
                color="info"
                size="small"
                onClick={handleBet}
              >
                Bet
              </MDButton>
            )}
            <p>{message}</p>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default NewBet;
